import React from "react";
import "./ReportMachineHourly.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";

const ReportMachineHourly = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location?.state?.date;
  const roomName = location?.state?.roomName;
  let arr = location?.state?.filterDateHour;
  const array = arr[0];

  let engineStatusCount = 0;
  let engineStatusPreviousValue = null;

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i].engineStatus;
    if (engineStatusPreviousValue === "0" && currentValue === "1") {
      engineStatusCount++;
    }
    engineStatusPreviousValue = currentValue;
  }

  let genSetStatusCount = 0;
  let genSetStatusPreviousValue = null;

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i].Gen_Status;
    if (genSetStatusPreviousValue === "0" && currentValue === "1") {
      genSetStatusCount++;
    }
    genSetStatusPreviousValue = currentValue;
  }

  let wapdaStatusCount = 0;
  let wapdaStatusPreviousValue = null;

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i].wapdaStatus;
    if (wapdaStatusPreviousValue === "1" && currentValue === "0") {
      wapdaStatusCount++;
    }
    wapdaStatusPreviousValue = currentValue;
  }


  const reportformat = "OMNI\t" + date.startDate + "\t\t" + date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">Event Report</div>
        </div>
        <div className="report" id="reportid">
          <div className="date">
            {date}
          </div>
          <div className="reportsMainContainer">
            <>
              <table className="tablemain" id="tablee">
                <thead className="tablehead">
                  <tr>
                    {array && array[array?.length - 1]?.datetime && (
                      <th className="tableh" rowSpan="2">
                        Time | Date
                      </th>
                    )}
                  </tr>
                  {array && (
                    <tr className="tablerow">
                      <th className="tableh">Total Fuel Consumption</th>
                      <th className="tableh">Total Fuel Fill</th>
                      <th className="tableh">Total Fuel Pilferage</th>
                      <th className="tableh">Total Run Hours</th>
                      <th className="tableh">Total kWh</th>
                      <th className="tableh">Engine Status</th>
                      <th className="tableh">Genset Status</th>
                      <th className="tableh">WAPDA Status</th>
                      <th className="tableh">Power</th>
                      <th className="tableh">Voltage</th>
                      <th className="tableh">Current</th>
                      <th className="tableh">Power Factor</th>
                      <th className="tableh">Frequency</th>
                      <th className="tableh">Available Fuel</th>
                      <th className="tableh">Oil Pressure</th>
                      <th className="tableh">Water Temperature</th>
                      <th className="tableh">Battery Voltage</th>
                    </tr>
                  )}
                </thead>
                <tbody className="tablebody">
                  {array?.map((ddd, i, newArray) => {
                    let previousTotal = i > 0 ? newArray[i - 1]?.totalizer : 0;
                    let difference = ddd?.totalizer - previousTotal;
                    return (
                      <tr className="tablerow" key={i}>
                        {array && (
                          <td className="tabled dateField">
                            {moment(ddd?.datetime).format("hh:mm | DD-MM-YYYY")}
                          </td>
                        )}
                        {array[array?.length - 1]?.totalfuelconsumed && (
                          <td className="tabled">{ddd?.totalfuelconsumed}</td>
                        )}
                        {array[array?.length - 1]?.totalrefueling && (
                          <td className="tabled">{ddd?.totalrefueling}</td>
                        )}
                        {array[array?.length - 1]?.totalpilferage && (
                          <td className="tabled">{ddd?.totalpilferage}</td>
                        )}
                        {array[array?.length - 1]?.totalGensetRunTime && (
                          <td className="tabled">{ddd?.totalGensetRunTime}</td>
                        )}
                        {array[array?.length - 1]?.totalKWH && (
                          <td className="tabled">{ddd?.totalKWH}</td>
                        )}
                        {array[array?.length - 1]?.engineStatus && (
                          <td className="tabled">
                            {ddd?.engineStatus === "1" ? "RUN" : "OFF"}
                          </td>
                        )}
                        {array[array?.length - 1]?.Gen_Status && (
                          <td className="tabled">
                            {ddd?.Gen_Status === "1" ? "ON" : "OFF"}
                          </td>
                        )}
                        {array[array?.length - 1]?.wapdaStatus && (
                          <td className="tabled">
                            {ddd?.wapdaStatus === "1" ? "ON" : "OFF"}
                          </td>
                        )}

                        {array[array?.length - 1]?.kw && (
                          <td className="tabled">{ddd?.kw}</td>
                        )}
                        {array[array?.length - 1]?.voltage && (
                          <td className="tabled">{ddd?.voltage}</td>
                        )}
                        {array[array?.length - 1]?.current && (
                          <td className="tabled">{ddd?.current}</td>
                        )}
                        {array[array?.length - 1]?.pf && (
                          <td className="tabled">{ddd?.pf}</td>
                        )}
                        {array[array?.length - 1]?.hz && (
                          <td className="tabled">{ddd?.hz}</td>
                        )}
                        {array[array?.length - 1]?.availablefuel && (
                          <td className="tabled">{ddd?.availablefuel}</td>
                        )}
                        {array[array?.length - 1]?.oilpressure && (
                          <td className="tabled">{ddd?.oilpressure}</td>
                        )}
                        {array[array?.length - 1]?.watertemp && (
                          <td className="tabled">{ddd?.watertemp}</td>
                        )}
                        {array[array?.length - 1]?.voltaDC && (
                          <td className="tabled">{ddd?.voltaDC}</td>
                        )}
                      </tr>
                    );
                  })}
                  <tr className="tablerow">
                    {array && <th className="tableh tablehead">Avg | Total</th>}
                    {array[array?.length - 1]?.totalfuelconsumed && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalfuelconsumed -
                            array?.[0]?.totalfuelconsumed 
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalrefueling && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalrefueling -
                            array?.[0]?.totalrefueling 
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalpilferage && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalpilferage -
                            array?.[0]?.totalpilferage 
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalGensetRunTime && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalGensetRunTime -
                            array?.[0]?.totalGensetRunTime 
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalKWH && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalKWH -
                            array?.[0]?.totalKWH 
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalKWH && (
                      <td className="tableh tablehead">{engineStatusCount}</td>
                    )}
                    {array[array?.length - 1]?.totalKWH && (
                      <td className="tableh tablehead">{genSetStatusCount}</td>
                    )}
                    {array[array?.length - 1]?.totalKWH && (
                      <td className="tableh tablehead">{wapdaStatusCount}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </>
          </div>
        </div>
        <div className="areaChartSteamFlow">
          <AreaChart1
            labels={array?.map((time) =>
              moment(time?.datetime).format("DD |HH:mm")
            )}
            availableFuel={array?.map((data) => parseInt(data?.availablefuel))}
            labelname1={"Available Fuel"}
            fuelFill={array?.map((data) => parseInt(data?.totalrefueling))}
            labelname2={"Total Fuel Fill"}
            fuelConsumed={array?.map((data) =>
              parseInt(data?.totalfuelconsumed)
            )}
            labelname3={"Total Fuel Consumed"}
            pilferage={array?.map((data) => parseInt(data?.totalpilferage))}
            labelname4={"Total Pilferage"}
          />
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachineHourly;
