import React from "react";
import GoBack from "../GoBack/GoBack";
import Logout from "../Logout/Logout";
import "./Footer.css";
import hunch from "../../../Asserts/Logo/hunch.png";

export default function Footer(props) {
  return (
    <>
      <div className="footer">
        {/* <div className="btns">
                    <GoBack />
                    <Logout />
                </div> */}
        <div className="develop">
          <div className="develophead">
            MRS9000 is a Monitoring & Reporting System using Amazon Web Server
            based on latest development frontend and backend algorithm tools
            with database. Our vision is to automate industries with MRS9000 to
            get the customer optimum performance of their machines and manpower
            utilization. For further information regarding our products and
            services please visit our site <a href="https://hunch.com.pk" target="_blank" rel="noopener noreferrer">www.hunch.com.pk</a>
          </div>
          {/* <div className="develophead">{props.comp}</div> */}
          <img className="hunchlogo" src={hunch} alt="Uploading" />
        </div>
      </div>
    </>
  );
}
