import React, { useState } from "react";
import "./ReportMachine.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import moment from "moment";
import { GetDeviceHourlyReportData } from "../../Service/DeviceAPI";
import { toast } from "react-toastify";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import AreaChart2 from "../Common/Charts/AreaChart1/AreaChart2";
import { Spin } from "antd";

const ReportMachine = () => {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const date = location?.state?.date;
  const roomName = location?.state?.roomName;
  let array = location?.state?.filterDate;

  const arr = array[0];
  let totalfuelconsumedCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const totalfuelconsumed = arr[i]?.totalfuelconsumed;
    totalfuelconsumedCalculation += parseInt(totalfuelconsumed);
  }
  let totalrefuelingCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const totalrefueling = arr[i]?.totalrefueling;
    totalrefuelingCalculation += parseInt(totalrefueling);
  }
  let totalpilferageCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const totalpilferage = arr[i]?.totalpilferage;
    totalpilferageCalculation += parseInt(totalpilferage);
  }
  let totalGensetRunTimeCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const totalGensetRunTime = arr[i]?.totalGensetRunTime;
    totalGensetRunTimeCalculation += parseInt(totalGensetRunTime);
  }
  let totalKWHCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const totalKWH = arr[i]?.totalKWH;
    totalKWHCalculation += parseInt(totalKWH);
  }
  let wapdaOffTimeCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const wapdaOffTime = arr[i]?.wapdaOffTime;
    wapdaOffTimeCalculation += parseInt(wapdaOffTime);
  }
  let Gen_StatusCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const Gen_Status = arr[i]?.Gen_Status;
    Gen_StatusCalculation += parseInt(Gen_Status);
  }
  let gensetLoadStatusCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const gensetLoadStatus = arr[i]?.gensetLoadStatus;
    gensetLoadStatusCalculation += parseInt(gensetLoadStatus);
  }
  let wapdaStatusCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const wapdaStatus = arr[i]?.wapdaStatus;
    wapdaStatusCalculation += parseInt(wapdaStatus);
  }
  let engineStatusCalculation = 0;
  for (let i = 0; i < arr.length; i += 1) {
    const engineStatus = arr[i]?.engineStatus;
    engineStatusCalculation += parseInt(engineStatus);
  }

  const reportformat = "OMNI\t" + date.startDate + "\t\t" + date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
        {
      loading && 
      <>
      <Spin size="large" fullscreen={true} />
      </>
    }
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">Daily Report</div>
        </div>
        <div className="report" id="reportid">
          <div className="date">
            {date.startDate} ~ {date.endDate}
          </div>
          <div className="reportsMainContainer">
            {array?.map((data, index) => {
              return (
                <>
                  <table className="tablemain" id="tablee" key={index}>
                    <thead className="tablehead">
                      <tr>
                        {index === 0 && data[data.length - 1]?.datetime && (
                          <th className="tableh" rowSpan="2">
                            Date
                          </th>
                        )}
                        <th className="tableh" colSpan="5">
                          {data?.title}
                        </th>
                      </tr>
                      {index === 0 && (
                        <tr className="tablerow">
                          <th className="tableh">Total Fuel Consumed</th>
                          <th className="tableh">Total Fuel Fill</th>
                          <th className="tableh">Total Pilferage</th>
                          <th className="tableh">Total Genset Run Time</th>
                          <th className="tableh">Total KWH</th>
                          <th className="tableh">Wapda Off Time</th>
                          <th className="tableh">Gen Status</th>
                          <th className="tableh">Gen set Load Status</th>
                          <th className="tableh">Wapda Status</th>
                          <th className="tableh">Engine Status</th>
                        </tr>
                      )}
                    </thead>
                    <tbody className="tablebody">
                      {data.map((ddd, i, newArray) => {
                        let previousTotal =
                          i > 0 ? newArray[i - 1]?.totalizer : 0;
                        let difference = ddd?.totalizer - previousTotal;
                        return (
                          <tr className="tablerow" key={i}>
                            {index === 0 && (
                              <td
                                className="tabled datelink"
                                onClick={async () => {
                                  // let filterHour = array?.map((meter) => {
                                  //   return {
                                  //     ...meter,
                                  //     HourReadings: meter?.HourReadings.filter(
                                  //       (val) => {
                                  //         let dateSearch = val?.date;
                                  //         if (dateSearch === ddd?.date) {
                                  //           return true;
                                  //         } else {
                                  //           return false;
                                  //         }
                                  //       }
                                  //     ),
                                  //   };
                                  // });
                                  // navigate(`/dev/reportHour`, {
                                  //   state: {
                                  //     filterDateHour: filterHour,
                                  //     date: ddd?.date,
                                  //     roomName: roomName,
                                  //   },
                                  // });
                                  setLoading(true)
                                  let payload = {
                                    machineId: ddd?.machineId,
                                    date: ddd?.datetime,
                                  };
                                  let res = await GetDeviceHourlyReportData(
                                    payload
                                  );
                                  if (res?.error != null) {
                                    setLoading(false)
                                    toast.error(res.error);
                                  } else {
                                    // setDeviceData(res?.data?.result);
                                    setLoading(false)
                                    navigate("/dev/reportHour", {
                                      state: {
                                        date: moment(ddd?.datetime).format(
                                          "DD-MM-YYYY"
                                        ),
                                        filterDateHour: res?.data?.result,
                                      },
                                    });
                                  }
                                  // console.log('this is the data', )
                                }}
                              >
                                {moment(ddd?.datetime).format("DD-MM-YYYY")}
                              </td>
                            )}
                            {data[data.length - 1]?.totalfuelconsumed && (
                              <td className="tabled">
                                {ddd?.totalfuelconsumed}
                              </td>
                            )}

                            {data[data.length - 1]?.totalrefueling && (
                              <td className="tabled">{ddd?.totalrefueling}</td>
                            )}

                            {data[data.length - 1]?.totalpilferage && (
                              <td className="tabled">{ddd?.totalpilferage}</td>
                            )}

                            {data[data.length - 1]?.totalGensetRunTime && (
                              <td className="tabled">
                                {ddd?.totalGensetRunTime}
                              </td>
                            )}

                            {data[data.length - 1]?.totalKWH && (
                              <td className="tabled">{ddd?.totalKWH}</td>
                            )}

                            {data[data.length - 1]?.wapdaOffTime && (
                              <td className="tabled">{ddd?.wapdaOffTime}</td>
                            )}

                            {data[data.length - 1]?.Gen_Status && (
                              <td className="tabled">{ddd?.Gen_Status}</td>
                            )}

                            {data[data.length - 1]?.gensetLoadStatus && (
                              <td className="tabled">
                                {ddd?.gensetLoadStatus}
                              </td>
                            )}
                            {data[data.length - 1]?.wapdaStatus && (
                              <td className="tabled">{ddd?.wapdaStatus}</td>
                            )}

                            {data[data.length - 1]?.engineStatus && (
                              <td className="tabled">{ddd?.engineStatus}</td>
                            )}
                          </tr>
                        );
                      })}
                      <tr className="tablerow">
                        {index === 0 && (
                          <th className="tableh tablehead">Avg | Total</th>
                        )}
                        <td className="tableh tablehead">
                          {parseInt(totalfuelconsumedCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(totalrefuelingCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(totalpilferageCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(totalGensetRunTimeCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(totalKWHCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(wapdaOffTimeCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(Gen_StatusCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(gensetLoadStatusCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(wapdaStatusCalculation)}
                        </td>
                        <td className="tableh tablehead">
                          {parseInt(engineStatusCalculation)}
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </>
              );
            })}
          </div>
        </div>
        {array?.map((data, i) => {
          return (
            <div className="areaChartSteamFlow" key={i}>
              <AreaChart2
                labels={data?.map((time) =>
                  moment(time?.datetime).format("DD |HH:mm")
                )}
                fuelFill={data?.map((data) => parseInt(data?.totalrefueling))}
                labelname1={"Total Fuel Fill"}
                fuelConsumed={data?.map((data) =>
                  parseInt(data?.totalfuelconsumed)
                )}
                labelname2={"Total Fuel Consumed"}
                pilferage={data?.map((data) => parseInt(data?.totalpilferage))}
                labelname3={"Total Pilferage"}
              />
            </div>
          );
        })}
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachine;
