import React from 'react'
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function AreaChart1(props) {

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: props.maxValueArea,
            }
        }

    };
    const labels = props.labels

    const label1 = props.labelname1
    const label2 = props.labelname2
    const label3 = props.labelname3
    const label4 = props.labelname4

    const data = {
        labels,
        datasets: [
            {
                fill: false,
                label: label1,
                data: props?.availableFuel,
                borderColor: 'rgb(255, 87, 51)',
                // backgroundColor: 'rgba(169, 204, 227, 0.7)',
                radius: 3,
                borderJoinStyle: 'round',
            },
            {
                fill: false,
                label: label2,
                data: props?.fuelFill,
                borderColor: 'rgb(0, 71, 171 )',
                // backgroundColor: 'rgba(237, 187, 153, 0.7)',
                borderJoinStyle: 'round',
            },
            {
                fill: false,
                label: label3,
                data: props?.fuelConsumed,
                borderColor: 'rgb(100, 149, 237 )',
                // backgroundColor: 'rgba(169, 204, 227, 0.7)',
                radius: 3,
                borderJoinStyle: 'round',
            },
            {
                fill: false,
                label: label4,
                data: props?.pilferage,
                borderColor: 'rgb(233, 116, 81)',
                // backgroundColor: 'rgba(237, 187, 153, 0.7)',
                borderJoinStyle: 'round',
            },
            
        ],
    };
  return (
    <>
    <Line options={options} data={data} height={285} width={350}/>
    </>
  )
}
